.tag {
  display: inline-flex;
  align-items: center;
  // margin-left: 1em;
  padding: 0.4em;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
  cursor: pointer;

  & .deleteIcon {
    margin-left: 10px;
  }
}
