.tags {
  // margin: 1em;
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: -0.5em;
  margin-left: 1em;
  margin-right: 1em;
  // margin-bottom: -0.5em;
}

.tags > * {
  margin-left: 0.5em;
  margin-bottom: 0.5em;
}

.tags >*:first-child {
  margin-left: 0;
}





