// .editorContainer {
//   position: relative;
//   display: flex;
//   width: 100%;

//   // & input {
//   //   display: block;
//   //   width: 100%;
//   //   text-align: center;
//   //   margin: auto;
//   // }

//   // & .editorHeader {
//   //   display: flex;
//   //   width: 100%;
//   //   justify-content: space-between;
//   //   padding: 1em;

//   // }

//   // & a {
//   //   color: black;
//   // }

//   // & .newDocButton {
//   //   margin-right: 2em;
//   //   margin-bottom: 0;
//   //   border: 0;
//   // }

//   // & .newDocButton, .deleteButton, .publishButton:hover {
//   //   cursor: pointer;
//   // }


//   // .deleteButton, .publishButton {
//   //   background: none;
//   //   border: none;
//   //   margin-right: 1em;
//   // }

//   // .titleForm {
//   //   position: absolute;
//   //   left: 50%;
//   //   transform: translate(-50%, 0);
//   //   width: 50%;
//   // }


//   // & .linkModal {
    
//   //   position: absolute;
//   //   z-index: 1;
//   // }
// }

.editor {
  width: 100%;
  height: 100%;
  padding: 0 0.5em;
}

// .mde {
//   width: 100%;
//   height: 100%;
// }

