.colorPicker {

  
  & .colorCircle {
    margin-left: 2px;
    margin-right: 10px;
    border-radius: 50%;
    height: 15px;
    width:  15px;
    position: relative;

    & .picker {
      position: absolute;
      left: 110%;
    }
  }
}