.modalContainer {
  border-radius: 5px;
}

.modal {
  display: flex;
  flex-direction: row;
  background: #eeeeee;
  opacity: 0.9;
  padding: 1em;

  & .selectedLink {
    color: red;
    margin-left: 5px;
    transition: margin-left 0.2s;
  }

  & .link {
    display: block;
  }
}

.modalHandle {
  visibility: hidden;
}