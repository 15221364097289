.backlink:hover {
  background: #eee;
}

.backlink {
  cursor: pointer;
  border-radius: 5px;
  padding: 0.5em;
  margin: 0.5em;
  overflow: hidden;
  max-height: 7em;
  text-overflow: ellipsis;

  & .backlinkHeader {
    color: #444;
    margin: 0;
    
  }
  

  & .backlinkContent {
    font-size: .75rem;
    color: #444;
  }
}