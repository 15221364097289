
.select {
  // border: 0;
  // background: 0;
  // background: linear-gradient(to bottom, #eee 0%,#cbcbcb 100%);
  // width: 50%;
  // text-align: center;
  // max-width: 100%;
  // max-width: 50%;
  text-align: center;
  background: 0;
  border: 0;
  padding: 0.25em;
  // border-radius: 5px;
  appearance: none;
  font-size: 16px;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: #555;
}

.select:hover,
.select:focus {
  color: black;
  cursor: pointer;
  box-shadow: 1px 1px 2px 1px #ccc;  
}