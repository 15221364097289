.backlinkContainer {
  margin: 1em;
  display: grid;
  grid-template-columns: 50% 50%;
  background: rgba(238, 238, 238, 0.25);
  border-radius: 10px;

  & .backlinksContainerHeader {
    margin: 1em;
    grid-column: 1 / 3;
    color: #333;
  }


}

