.newNote {
  display: inline-flex;
  align-items: center;
  position: relative;

  & .newNoteInput {
    font-style: italic;
    font-size: 18px;
    width: 100%;
    padding: 1em;
  }


  & .colorPickerContainer {
    margin-left: 2em;
  }

}


.inputFocus .hashTag {
  opacity: 1;
}