.horizontalPane {
  // box-shadow: 3px 0px 7px 2px #aaaaaa;
  background: white;
  // margin-right: 4px;
  // border-right: 1px solid #ccc;
  border-left: 1px solid #ddd;
  // padding: 0 1em 0 1em;
  height: 100%;
  overflow-y: auto;
  position: sticky;
  color: #333333;
}

.horizontalPane:last-child {
  border-right: 1px solid #ddd;
}

.paneHeader {
  position: sticky;
  z-index: 1000;
  text-align: left;
  & a {
    text-decoration: none;
    color: black;
  }
}

.collapsedLeft > .paneContent, .collapsedRight > .paneContent {
  visibility: hidden;
}

.collapsedLeft > .paneHeader > *, .collapsedRight > .paneHeader > * {
  margin-top: 0.25em;
  margin-left: -0.7em;
  padding: 0;

}

.collapsedLeft > .paneHeader > * {
  writing-mode: vertical-lr;
  writing-mode: sideways-lr;
}


.collapsedRight > .paneHeader > * {
  writing-mode: vertical-rl;
  writing-mode: sideways-rl;
}

