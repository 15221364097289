.feedbackContainer {
  height: 100vh;
  margin-left: 5%;
}

.feedback {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 3em;
  
  & .feedbackHeader {
    margin-bottom: 2em;
    
  }

  & .feedbackForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 50%;
    margin-left: 0;
    margin-bottom: 0;
    width: 50%;
  }
  
  & textarea {
    display: block;
    width: 100%;
    height: 50%;
    margin-bottom: 3em;
    
  }

  & input {
    margin-left: auto;
    margin-bottom: 3em;
    width: 50%;
  }

  button {
    border: 1px solid #ccc;
    padding: 2em;
    margin-left: auto;
    
  }

  button:hover, button:focus {
    background: #eee;
    font-weight: bolder;
  }

  & h3 {
    margin-top: 0;
  }
}