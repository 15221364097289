.readerView {
  display: flex;
  padding: 0;
  margin:0;
  height: 100%;
}

.readerContainer {
  height: 93%;
  overflow-x: auto;
}