.input:focus {
  border-color: rgba(180, 180, 180, 0.7);
}

.input {
  border-radius: 4px;
  width: 100%;
  border: 0;
}

.input:focus,
.input:hover {
  box-shadow: 1px 1px 2px 1px #ddd;  

}

.input:hover::placeholder,
.input:focus::placeholder {
  color: black;
  opacity: 1;
}

.invalid {
  background-color: rgba(255, 0, 0, 0.3);
}