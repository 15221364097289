.editorHeader {
  background: white;
  cursor: pointer;
  margin-right: 1em;

  & h3 {
    margin: 0;
    padding: 1.01em;
    // padding-bottom: 0.5em;
  }
}

.inputClass {
  font-size: 1.15rem;
  padding: 0.5em;
  margin: 0.5em;
  border: 0;
  text-align: left;
}