.noteLine {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 0.2em;

  & .noteButtons {
    margin-left: auto;
    display: inline-flex;

    & * {
      cursor: pointer;
      margin-left: 7px;
      margin-right: 7px;
    }
    
  }

  
  & a {
    margin-left: 2px;
    line-height: 1.6;
    text-decoration: none;
    color: #333;
  }

  & a:hover {
    color: black;
  }
}
