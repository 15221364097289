.header {
  height: 7%;
  position: sticky;
  left: 1em;
  top: 0;
  background: white;
  z-index: 999;
  padding: 0 1em 0 1em;
  display: flex;
  border-bottom: 1px solid #ddd;

}



.headerButton {
  display: inline-flex;
  align-items: center;
  margin-right: 2em;
  padding: 1em;
    
  & svg {
    margin-right: 0.75em;
  }
  
}

.headerButton:last-child {
  margin-right: 0;
}