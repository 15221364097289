.filter {
  margin-left: 2em;
  display: inline-flex;
  
}



.clearFilterButton {
  font-size: 16px;
}