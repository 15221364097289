.popover {
  background-color: white;
  text-align: left;
  box-shadow: 1px 1px 6px 1px #ddd;
  padding: 1em;

  

  & h3 {
    margin: 0.75em 0 0.75em 0;
  }
}

.renderedLink {
  color: blue;
}